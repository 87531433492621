.item_s[data-v-c2f1f4d6] {
  margin-bottom: 10px;
}
.table-big[data-v-c2f1f4d6] {
  width: 100%;
  height: 320px;
  overflow: auto;
  border: 1px solid #eee;
  margin-top: 10px;
}
.table-big .table[data-v-c2f1f4d6] {
  min-width: 3000px;
  height: 400px;
}
.table-big .table .table-div[data-v-c2f1f4d6] {
  vertical-align: top;
  display: inline-block;
  width: 100px;
  border-right: 1px solid #eee;
}
.table-big .table .table-div div[data-v-c2f1f4d6] {
  height: 30px;
  line-height: 30px;
  width: 100%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #333;
  padding: 0 5px;
}
.table-big .table .table-div .iconfont icon-shanchu[data-v-c2f1f4d6] {
  cursor: pointer;
}
.table-big .table .table-div:first-child .top_icon i[data-v-c2f1f4d6] {
  display: none;
}
.table-big .table .table-div[data-v-c2f1f4d6]:first-child {
  width: 26px !important;
}
.table-big .table .top_icon[data-v-c2f1f4d6] {
  width: 100%;
  border-bottom: 1px solid #eee;
}
.table-big .table .top_icon i[data-v-c2f1f4d6] {
  font-size: 16px;
  color: #F56C6C;
  cursor: pointer;
  margin-top: 1px;
}
.table-big .table .table_icon[data-v-c2f1f4d6] {
  border-bottom: 1px solid #eee;
}
.table-big .table .table_icon i[data-v-c2f1f4d6] {
  font-size: 16px;
  color: #F56C6C;
  cursor: pointer;
  margin-top: 7px;
}
.common_dialog_box .item[data-v-c2f1f4d6] {
  margin-left: 0px !important;
}
.common_dialog_box .common_title[data-v-c2f1f4d6] {
  font-weight: 600;
}
.tableHead[data-v-c2f1f4d6] {
  display: inline-block;
  width: 100px;
  text-align: center;
  border-right: 1px solid #eee;
  height: 16px;
}